<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>檢視總公司門市庫存品團購</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row">
        <div class="col-12 col-xl-9">
          <h4 class="mb-2 font-weight-bold">
            檢視總公司門市庫存品團購
          </h4>

          <section class="mb-5">
            <div class="h5">Step 1: 請選擇分店</div>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="團購分店"
              label-for="input-1"
            >
            <b-card v-if="groupbuying.branches" no-body class="mb-1">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle.branchTable variant="secondary"
                  >共：{{ groupbuying.branches.length }} 筆</b-button
                >
              </b-card-header>
              <b-collapse id="branchTable" accordion="my-accordion" role="tabpanel">
                <b-card-body class="branch_table_card_body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>門市</th>
                        <th>開始時間</th>
                        <th>結束時間</th>
                        <th>庫存</th>
                        <th>每人最高購買數</th>
                        <th>每人最低購買數</th>
                        <th>下單數量遞增數</th>
                        <th>狀態</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="branch in groupbuying.branches" :key="branch.id">
                        <td>{{ branch.branch_code }} {{ branch.name }}</td>
                        <td>{{ branch.pivot.start_at }}</td>
                        <td>{{ branch.pivot.end_at }}</td>
                        <td>{{ branch.pivot.total_sku_quantity }}</td>
                        <td>{{ branch.pivot.once_max_quantity }}</td>
                        <td>{{ branch.pivot.once_quantity }}</td>
                        <td>{{ branch.pivot.once_increase_quantity }}</td>
                        <td>
                          <b-badge v-if="branch.pivot.status === 'success'" variant="success">成功</b-badge>
                          <b-badge v-else-if="branch.pivot.status === 'failed'" variant="danger">
                            失敗: {{ branch.pivot.memo }}
                          </b-badge>
                          <b-badge v-else variant="secondary">未開團</b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
              </b-collapse>
            </b-card>
            </b-form-group>
          </section>

          <section class="mb-8">
            <div class="h5">Step 2: 請編輯團購設定</div>

            <div class="mb-4">
              <b-form-group
                label-cols="12"
                content-cols="12"
                content-cols-lg="7"
                label-size="sm"
                label="請輸入商品編號，輸入完畢後，請按確認"
              >
                <div>
                  <b-input-group>
                    <b-form-input
                      placeholder="請輸入商品編號"
                      v-model="code"
                      :disabled="true"
                      :state="v$.form.product_ids.$error ? false : null"
                      @keyup.enter="handleSearchProducts"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="true"
                        @click="handleSearchProducts"
                        >確認</b-button
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback
                      :state="!v$.form.product_ids.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-input-group>

                  <ProductViewer
                    :loading="isFetchProduct"
                    :product="selectedProduct"
                  />
                </div>
              </b-form-group>
            </div>

            <div>
              <!-- 庫存 input -->
              <b-form-group
                label-cols="7"
                label-cols-lg="2"
                label-size="sm"
                label="庫存共"
                label-for="max_quantity"
              >
                <b-form-input
                  id="max_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.max_quantity"
                  :state="v$.form.max_quantity.$error ? false : null"
                  :disabled="true"
                ></b-form-input>
                件
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最高購買數"
                label-for="once_max_quantity"
              >
                <b-form-input
                  id="once_max_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_max_quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :state="v$.form.once_max_quantity.$error ? false : null"
                  :disabled="true"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.once_max_quantity.less.$invalid"
                >
                  需小於商品總數上限
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最低購買數"
                label-for="once_quantity"
              >
                <b-form-input
                  id="once_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_quantity"
                  :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                  :disabled="true"
                  :state="v$.form.once_quantity.$error ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.once_quantity.less.$invalid"
                >
                  需小於每人最高購買數
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="下單數量遞增數"
                label-for="once_increase_quantity"
              >
                <b-form-input
                  id="once_increase_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_increase_quantity"
                  :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                  :disabled="true"
                  :state="v$.form.once_increase_quantity.$error ? false : null"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                label="開始時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.start_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled="true"
                      :disabledDates="disabledDates"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at.date.required.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="form.start_at.time"
                      :input-class="['form-control']"
                      :disabled="true"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.start_at.time.HH.$error ||
                        !v$.form.start_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="截止時間"
                content-cols="12"
                content-cols-lg="6"
                description="*達截止時間系統將自動結團"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.end_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :input-class="{
                        'is-invalid': v$.form.start_at.$invalid,
                      }"
                      :disabledDates="disabledDates"
                      :disabled="true"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.end_at.date.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                    >
                      截止時間必須大於開團時間
                    </b-form-invalid-feedback>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      :input-class="[
                        'form-control',
                        {
                          'is-invalid':
                            v$.form.start_at.time.HH.$error ||
                            v$.form.start_at.time.mm.$error ||
                            v$.form.start_at.date.beforeEndAt.$invalid,
                        },
                      ]"
                      :disabled="true"
                      v-model="form.end_at.time"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.end_at.time.HH.$error ||
                        !v$.form.end_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="tracking_code"
              >
                <b-form-input
                  id="tracking_code"
                  class="mb-2 mr-sm-2 mb-sm-0 w-100 w-lg-35"
                  v-model="form.tracking_code"
                  :formatter="
                    (value) => (value.length > 6 ? value.slice(0, 6) : value)
                  "
                  :state="v$.form.tracking_code.$error ? false : null"
                  :disabled="true"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="v$.form.tracking_code.required.$invalid"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="v$.form.tracking_code.lengthSix.$invalid"
                >
                  需為6碼數字
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="順序"
                label-for="order"
              >
                <b-form-input
                  id="order"
                  class="mb-2 mr-sm-2 mb-sm-0 w-100 w-lg-35"
                  v-model="form.order"
                  type="number"
                  :disabled="true"
                  :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                ></b-form-input>
                <b-form-invalid-feedback v-if="v$.form.order.required.$invalid">
                  此欄位為必填
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </section>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-button
          class="mr-4"
          variant="outline-danger"
          @click="handleCancelGroupbuy"
          :disabled="isFetchGroupbuying || isSubmmiting"
        >
          <template>返回</template>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import {
  addDays,
  format,
  isBefore,
  set,
  subDays,
  isWithinInterval,
} from "date-fns";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { zh } from "vuejs-datepicker/dist/locale";
import ProductViewer from "@/components/Page/Dashboard/ProductViewer";

import groupbuyApi from "@/apis/main-groupbuyings";
import groupTypeApi from "@/apis/group-type";

import { GROUPBUYING_STATUS } from "../Groupbuyings/utils";

const BRANCH_GROUP = {
  ALL: "all",
  ONE: "one",
  EXCEL: "excel",
};

const MODE = {
  CREATE: "create",
  EDIT: "edit",
  READONLY: "readonly",
};

export default {
  components: {
    Datepicker,
    VueTimepicker,
    ProductViewer,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      zh,
      MODE,
      BRANCH_GROUP,
      branchGroup: BRANCH_GROUP.ALL,
      code: null,
      isFetchProduct: false,
      isFetchGroupbuying: false,
      isSubmmiting: false,
      selectedProduct: null,
      selectedGroupTypes: [],
      groupOptions: null,
      disabledDates: { to: subDays(new Date(), 1) },
      branch_id: null,
      // response 回來的 branch 資料
      targetBranches: [],
      excelBranches: [],
      groupTypes: [],
      form: {
        is_all: false,
        group_id: null,
        product_ids: null,
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 下單數量遞增數
        is_force_confirmed: false, // 強制結單
        max_quantity: 0, // 商品總數上限
        tracking_code: 999999,
        branch_ids: [],
        group_type_ids: [],
        order: 1,
        payday_period: 0,
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
      },
      groupbuying: null,
      GROUPBUYING_STATUS,
    };
  },

  validations() {
    return {
      form: {
        product_ids: { required },
        group_type_ids: { required },
        tracking_code: {
          required,
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return /^[0-9]{6}$/.test(this.form.tracking_code);
          },
        },
        order: {
          required,
        },
        max_quantity: {
          required,
          lessThanSalableQuantity: () => {
            return this.salableQuantity
              ? this.salableQuantity >= this.form.max_quantity
              : true;
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
      },
    };
  },

  async created() {
    await this.getGroupbuying();
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["branchOptions"]),
    mode() {
      if (this.$route.name === "MainGroupbuyingsEdit") {
        return MODE.EDIT;
      }

      if (this.$route.name === "MainGroupbuyingsDetail") {
        return MODE.READONLY;
      }

      return MODE.CREATE;
    },
    title() {
      const modeTitleMap = {
        [MODE.CREATE]: "新增總公司團購",
        [MODE.EDIT]: "編輯總公司團購",
        [MODE.READONLY]: "檢視總公司團購",
      };

      return modeTitleMap[this.mode];
    },
    estimatedEndDate() {
      return format(
        addDays(this.form.end_at.date, this.form.payday_period),
        "yyyy-MM-dd"
      );
    },
    salableQuantity() {
      return this.selectedProduct?.sku_list?.[0]?.quantity;
    },
    status() {
      return this.groupbuying?.status?.substring(0, 1);
    },
    groupbuyID() {
      return this.$route.params.groupbuyID;
    },
  },

  methods: {
    async fetchGroupTypes() {
      const { data } = await groupTypeApi.list();

      this.groupTypes = [
        ...data.data.map((groupType) => {
          return {
            id: groupType.id,
            name: groupType.name,
          };
        }),
      ];
    },
    async getGroupbuying() {
      try {
        this.isFetchGroupbuying = true;
        const { data } = await groupbuyApi.getGroupbuying(
          this.$route.params.groupbuyID
        );

        const {
          payday_period,
          max_quantity,
          is_force_confirmed,
          once_quantity,
          once_increase_quantity,
          once_max_quantity,
          start_at,
          end_at,
          products,
          tracking_code,
          present_target_branches,
          present_target_group_types,
          order,
        } = data.data;

        this.groupbuying = data.data;

        this.form = {
          ...this.form,
          payday_period,
          is_force_confirmed,
          once_quantity,
          once_max_quantity,
          once_increase_quantity,
          max_quantity,
          tracking_code,
          order,
          start_at: {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          },
          end_at: {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          },
          branch_ids: Object.keys(present_target_branches),
          group_type_ids: present_target_group_types,
        };

        this.targetBranches = Object.values(present_target_branches);
        this.code = products[0]?.sale_page_code;
        this.selectedProduct = products[0];
        this.form.product_ids = [products[0].id];
        this.isFetchGroupbuying = false;
      } catch (err) {
        console.log("err", err);
      }
    },

    async handleSearchProducts() {
      if (!this.code) {
        return;
      }
      try {
        this.isFetchProduct = true;
        const { data } = await groupbuyApi.getGroupBuyProducts(this.code);

        if (data.data.is_closed) {
          throw new Error("is_closed");
        }
        if (isBefore(new Date(data.data.available_end_at), new Date())) {
          throw new Error("available_end_at", {
            cause: data.data.available_end_at,
          });
        }
        this.selectedProduct = data.data;
        this.form.product_ids = [this.selectedProduct.id];
        this.form.once_max_quantity =
          this.selectedProduct.sku_list[0]?.once_max_quantity ?? 1;
        this.form.once_quantity =
          this.selectedProduct.sku_list[0]?.once_quantity ?? 1;
        this.form.once_increase_quantity =
          this.selectedProduct.sku_list[0]?.once_increase_quantity ?? 1;
      } catch (error) {
        this.selectedProduct = null;
        let errorMessage;
        switch (error.message) {
          case "is_closed":
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>商品已關閉`;
            break;
          case "available_end_at":
            errorMessage = `您輸入的商品頁編號：${
              this.code
            } <br/>商品已於 ${format(
              new Date(error.cause),
              "yyyy-MM-dd HH:mm"
            )} 結束銷售`;
            break;
          default:
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>查無此商品`;
        }
        this.$swal({
          type: "error",
          html: errorMessage,
        }).then(() => {
          this.code = null;
        });
      }
      this.isFetchProduct = false;
    },
    handleCancelGroupbuy() {
      this.$router.push({ name: "MainGroupbuyingsList", query: { tab: 'going' } });
    },
    validateProductTime() {
      if (!this.selectedProduct) return false;
      const groupbuyStartAt = new Date(
        format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss" // for safari data format compatible
        )
      );
      const groupbuyEndAt = new Date(
        format(
          set(new Date(this.form.end_at.date), {
            hours: Number(this.form.end_at.time.HH),
            minutes: Number(this.form.end_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss"
        )
      );

      /**
       * 產品銷售時間 start <= 開團時間 start <= 產品銷售時間 end
       * 產品銷售時間 start <= 開團時間 end <= 產品銷售時間 end
       */
      return (
        isWithinInterval(groupbuyStartAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        }) &&
        isWithinInterval(groupbuyEndAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        })
      );
    },

    async handleSubmitGroupbuy() {
      const result = await this.v$.$validate();
      await this.validateProductTime();
      let isProductTimeValid = true;
      if (!this.selectedProduct) {
        isProductTimeValid = await this.validateProductTime();
      }

      if (!isProductTimeValid) {
        this.$swal({
          type: "error",
          html: `團購時間已超出商品的銷售時間<br/>(${format(
            new Date(this.selectedProduct.available_start_at),
            "yyyy-MM-dd HH:mm"
          )} ~ ${format(
            new Date(this.selectedProduct.available_end_at),
            "yyyy-MM-dd HH:mm"
          )})<br/>無法新增團購`,
          animation: false,
          customClass: "animated tada",
        });
      }
      if (!result || !isProductTimeValid) return;

      await this.handleSetBranchValue();
      const form = {
        ...this.form,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      if (this.mode === MODE.EDIT) {
        this.handleUpdateGroupbuy(form);
      } else {
        this.handleCreateGroupbuy(form);
      }
    },

    async handleUpdateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.updateGroupbuying(
          this.$route.params.groupbuyID,
          form
        );
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "MainGroupbuyingsList" });
          });
      } catch (error) {
        console.log("");
      }
      this.isSubmmiting = false;
    },

    async handleCreateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.createGroupbuy(form);
        this.$swal
          .fire({
            icon: "success",
            text: "新增成功",
          })
          .then(() => {
            this.$router.push({ name: "MainGroupbuyingsList" });
          });
      } catch (error) {
        if (error.response.status === 400) {
          this.$swal("找不到群組", null, "error");
        }
      }
      this.isSubmmiting = false;
    },

    handleSetBranchValue() {
      if (this.branchGroup === BRANCH_GROUP.ALL) {
        delete this.form.branch_ids;
        this.form.is_all = true;
      }
      if (this.branchGroup === BRANCH_GROUP.ONE) {
        this.form.branch_ids = [this.branch_id];
      }
      if (this.branchGroup === BRANCH_GROUP.EXCEL) {
        this.form.branch_ids = this.excelBranches;
      }
    },

    async uploadBranches(file) {
      let formData = new FormData();
      formData.append("file", file);

      try {
        const {
          data: { data },
        } = await groupbuyApi.uploadExcel(formData);

        this.excelBranches = data.map((b) => b.id);
      } catch (e) {
        console.error(e);
      }
    },

    async handleDelete() {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div> <strong>團購編號：${this.groupbuying.groupbuying_number}</strong></div>
                <div>商品名稱：${this.groupbuying.products?.[0].title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await groupbuyApi.deleteGroupbuying(
                this.groupbuyID
              );
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
  },
};
</script>

<style>
/* Default override (not using "append-to-body") */
.vdp-datepicker input.is-invalid,
.vue__time-picker input.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vdp-datepicker input:disabled,
.vue__time-picker input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}

.custom-radio {
  margin-bottom: 4px;
}
.custom-radio .custom-control-label {
}

.branch_table_card_body{
  padding: 1.05rem 1rem !important;
}
</style>
